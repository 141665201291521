.canvasheader {
  width: 100%;
}

.row {
  display: flex;
}
.row .key {
  width: 5em;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.row .value {
  flex: 1;
  padding: 0 0.5em;
}

.mockPageContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}
.mockPageContainer .backSetting {
  min-width: 40px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #1e80ff;
  color: #1e80ff;
  line-height: 60px;
  padding: 0 10px;
  background-color: #ccc;
  transition: all 300ms;
}
.mockPageContainer .backSetting.isPreview {
  border: 0;
  color: transparent;
  background-color: transparent;
}
.mockPageContainer .w100 {
  width: 100%;
}
.mockPageContainer .contentScroll {
  flex: 1;
  overflow: auto;
}
.likeContainer {
  display: flex;
  margin: 10px 30px 10px 10px;
  border-radius: 4px;
  position: relative;
}
.likeContainer .triangle {
  position: absolute;
  bottom: 100%;
  left: 15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent currentColor transparent;
}
.likeContainer .likeIcon {
  padding: 20px 15px;
}

.avatars {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
}
.avatars .item {
  padding: 2px;
}
.avatars .item .radius {
  overflow: hidden;
  border-radius: 4px;
  background-color: #fff;
}