.table {
  width: 100%;
  $borderBColor: #333;
  $borderBWidth: 2px;
  border: $borderBWidth solid $borderBColor;
  .row {
    display: flex;
    &.topLine {
      border-top: $borderBWidth solid $borderBColor;
    }
    .td {
      flex: 1;
      user-select: none;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.5px solid #999;
      &.leftLine {
        border-left: $borderBWidth solid $borderBColor;
      }
    }
  }
}
