:root {
  --theme-color: #4191f5;
  --theme-color-logo: rgb(0, 120, 212);
}

.pageContainer {
  position: relative;
}
.pageContainer .log {
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  color: #fff;
}
.pageContainer .video {
  --width: 100%;
  width: var(--width);
  height: calc(var(--width) * 0.75);
}
.pageContainer .canvas {
  display: none;
}
.pageContainer .btnStart {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border: none;
  box-shadow: 0 5px 20px rgba(65, 145, 245, 0.3);
  color: #fff;
  margin: 0;
  user-select: none;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 280px;
  background-color: var(--theme-color);
}
.pageContainer .btnStartInput {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border: none;
  box-shadow: 0 5px 20px rgba(65, 145, 245, 0.3);
  color: #fff;
  margin: 0;
  user-select: none;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 80px;
  background-color: var(--theme-color);
}
.pageContainer .buttons {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn {
  background-color: #99b898;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  width: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  background-color: var(--theme-color);
  border: none;
  box-shadow: 0 5px 20px rgba(65, 145, 245, 0.3);
  color: #fff;
  transition: background-color 0.25s, box-shadow 0.25s, color 0.25s, opacity 0.25s;
}

.cameraBtns {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.cameraBtns .btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border: none;
  box-shadow: 0 5px 20px rgba(65, 145, 245, 0.3);
  color: #fff;
  margin: 0;
  user-select: none;
}
.cameraBtns .btnStop {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border: none;
  box-shadow: 0 5px 20px rgba(65, 145, 245, 0.3);
  color: #fff;
  margin: 0;
  user-select: none;
  background-color: red;
}

.orcResultContainer {
  position: relative;
}
.orcResultContainer > div {
  position: absolute;
  white-space: nowrap;
  background-color: rgba(65, 145, 245, 0.3);
}
.orcResultContainer > div.huidan {
  background-color: rgba(245, 65, 122, 0.3);
}