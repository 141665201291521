:root {
    --theme-color: #4191f5;
    --theme-color-logo: rgb(0, 120, 212);
}

.protalPage {
  height: 100vh;
//   background-color: #2a363b;
  .buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .btn {
      background-color: #99b898;
      padding: 10px 20px;
      color: #fff;
      border-radius: 5px;
      width: 8em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1em;
      background-color: var(--theme-color);
      border: none;
      box-shadow: 0 5px 20px rgb(65 145 245 / 30%);
      color: #fff;
      transition: background-color 0.25s, box-shadow 0.25s, color 0.25s,
        opacity 0.25s;
    }
  }
}
