:root {
  --theme-color: #4191f5;
  --theme-color-logo: rgb(0, 120, 212);
}
$btnSize: 80px;
@mixin button-circle-style {
  width: $btnSize;
  height: $btnSize;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border: none;
  box-shadow: 0 5px 20px rgb(65 145 245 / 30%);
  color: #fff;
  margin: 0;
  user-select: none;
}

.pageContainer {
  position: relative;
  //   background-color: #2a363b;

  .log {
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
    color: #fff;
  }
  .video {
    --width: 100%;
    width: var(--width);
    height: calc(var(--width) * 0.75);
  }
  .canvas {
    display: none;
  }
  .snapshot {
    // position: absolute;
    // top: 0;
    // left: 0;
  }
  .btnStart {
    @include button-circle-style;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 280px;
    background-color: var(--theme-color);
  }
  .btnStartInput {
    @include button-circle-style;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 80px;
    background-color: var(--theme-color);
  }
  .buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.btn {
  background-color: #99b898;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  width: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  background-color: var(--theme-color);
  border: none;
  box-shadow: 0 5px 20px rgb(65 145 245 / 30%);
  color: #fff;
  transition: background-color 0.25s, box-shadow 0.25s, color 0.25s,
    opacity 0.25s;
}

.cameraBtns {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  .btn {
    @include button-circle-style;
  }
  .btnStop {
    @include button-circle-style;
    background-color: red;
  }
}

.orcResultContainer {
  position: relative;
  & > div {
    position: absolute;
    white-space: nowrap;
    background-color: rgb(65 145 245 / 30%);
    &.huidan{
      background-color: rgba(245, 65, 122, 0.3);
    }
  }
}
