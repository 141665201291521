.app {
  height: 100vh;
  display: flex;
  position: relative;
}
.app .colorBoard {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.app .colorBoard > div {
  flex: 1;
  padding-left: 1.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s;
}
.app .colorBoard > div:nth-child(1) {
  flex: 3;
  justify-content: flex-end;
  padding-bottom: 1em;
}
.app .colorBoard > div:nth-child(5) {
  flex: 8;
  justify-content: flex-start;
  padding-top: 1em;
}
.app .Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.app .Footer > div {
  height: 3em;
  border-radius: 0.2em;
  color: #fff;
  display: flex;
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  border-bottom: 0;
}
.app .Footer > div.current {
  background: rgba(168, 168, 168, 0.5);
  border-bottom: 0;
  height: 5em;
}
.app .Footer > div .colorBoard > div:nth-child(1) {
  flex: 1;
  justify-content: flex-end;
  padding-bottom: 0;
}
.app .Footer > div .colorBoard > div:nth-child(5) {
  flex: 1;
  justify-content: flex-start;
  padding-top: 0;
}