.table {
  width: 100%;
  border: 2px solid #333;
}
.table .row {
  display: flex;
}
.table .row.topLine {
  border-top: 2px solid #333;
}
.table .row .td {
  flex: 1;
  user-select: none;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #999;
}
.table .row .td.leftLine {
  border-left: 2px solid #333;
}